<template>
    <header class="page-title">
        <h1><i class="bi bi-bar-chart-fill"></i> レポート - アポ転換率</h1>
    </header>

    <section class="section">
        <div class="row align-items-end">
            <div class="col-6">
                <label class="form-label">事業部</label>
                <form-select
                    v-model="condition.department_id"
                    :options="options_department"
                    empty_option="全て"
                ></form-select>
            </div>
            <div class="col-12">
                <label class="form-label">集計期間</label>
                <div class="input-group">
                    <form-input-date
                        v-model="condition.date_from"
                    ></form-input-date>
                    <span class="input-group-text">〜</span>
                    <form-input-date
                        v-model="condition.date_to"
                    ></form-input-date>
                </div>
            </div>
            <div class="col-6">
                <button @click="search()" :disabled="loading_count > 0" class="btn btn-primary">表示</button>
            </div>
        </div>
    </section>

    <section class="section">
        <inline-loader v-if="loading_count > 0"></inline-loader>
        <div v-else class="table-responsive" style="width: 100%;">
            <template v-if="stats.length > 0">
                <table class="table table-bordered table-hover">
                    <thead>
                        <tr class="table-dark">
                            <th>担当者</th>
                            <th>反響数</th>
                            <th>アポ数</th>
                            <th>転換率</th>
                            <th>担当アポ数</th>
                            <th>担当転換率</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="stat,i of stats" :key="i">
                            <td>{{ stat.user_name }}</td>
                            <td class="text-end">{{ stat.contact_count }}</td>
                            <td class="text-end">{{ stat.appointment_count }}</td>
                            <td class="text-end">{{ stat.appointment_rate }}</td>
                            <td class="text-end">{{ stat.direct_appointment_count }}</td>
                            <td class="text-end">{{ stat.direct_appointment_rate }}</td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr class="table-light">
                            <td>合計</td>
                            <td class="text-end">{{ total_contact_count }}</td>
                            <td class="text-end">{{ total_appointment_count }}</td>
                            <td class="text-end">{{ total_appointment_rate }}</td>
                            <td class="text-end">{{ total_direct_appointment_count }}</td>
                            <td class="text-end">{{ total_direct_appointment_rate }}</td>
                        </tr>
                    </tfoot>
                </table>
                <p>反響数は、反響日が対象期間内のHOTのみ、アポ数は、アポ取得日が対象期間内の初回アポのみをカウントしています。<br>
                事業部を選択した場合、現時点の事業部で絞り込まれます。<br>
                クズ・お断り・重複（カウントしない）を除きます。</p>
            </template>
            <template v-else>
                <p class="alert alert-secondary">データがありません</p>
            </template>
        </div>
    </section>

</template>

<script>
import FormSelect from '@/components/forms/FormSelect';
import FormInputDate from '@/components/forms/FormInputDate';
import InlineLoader from '@/components/tools/InlineLoader.vue';
import Department from '@/models/entities/department';

export default {
    name: 'AppointmentRate',
    components: {
        InlineLoader,
        FormInputDate,
        FormSelect,
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showErrorMessage',
        'showMessage',
    ],
    data() {
        return {
            // ローディング
            loading_count: 0,

            // 検索フォーム
            options_department: [],
            exists_options_department: false,

            // 検索条件
            condition: {
                department_id: null,
                date_from: null,
                date_to: null,
            },

            // 表示データ
            stats: [],
        }
    },
    mounted() {
        this.fetchDepartments();
        this.initConditionDate();
    },
    methods: {
        fetchDepartments() {
            this.loading_count++;

            // 事業部マスタ取得
            this.$http.get('/master/department')
            .then(response => {
                for (let row of response.data) {
                    let department = new Department(row);
                    this.options_department.push({value: department.department_id, label: department.department_name});
                }

                // 事業部初期値セット
                if (this.$store.state.auth.department_id) {
                    this.condition.department_id = this.$store.state.auth.department_id;
                } else {
                    this.condition.department_id = this.options_department[0].value;
                }

                this.search();
            })
            .finally(() => {
                //ローディング終了
                this.loading_count--;
            })
        },
        // 検索
        search() {
            if (
                this.$helper.isBlank(this.condition.date_from)
                || this.$helper.isBlank(this.condition.date_to)
            ) {
                this.showErrorMessage('集計期間を設定してください');
                return true;
            }

            // ローディング開始
            this.loading_count++;
            // 表示データ初期化
            this.stats.splice(0);

            this.$http.get('/report/appointment-rate', {
                params: this.condition,
            })
            .then(response => {
                this.stats = response.data;
            })
            .finally(() => {
                // ローディング終了
                this.loading_count--;
            })
        },
        thisMonday(date) {
            const this_year = date.getFullYear();
            const this_month = date.getMonth();
            const dates = date.getDate();
            const day_num = date.getDay();
            const this_monday = dates - day_num + 1;
            return new Date(this_year, this_month, this_monday);
        },
        initConditionDate() {
            let today = new Date();
            let first_of_month = new Date(today.getFullYear(), today.getMonth(), 1);
            let last_of_month = new Date(today.getFullYear(), today.getMonth() + 1, 0);

            this.condition.date_from = this.$helper.ymdForInputDate(first_of_month);
            this.condition.date_to = this.$helper.ymdForInputDate(last_of_month);
        },
    },
    computed: {
        total_contact_count() {
            return this.stats.reduce((prev, stat) => prev + stat.contact_count, 0);
        },
        total_appointment_count() {
            return this.stats.reduce((prev, stat) => prev + stat.appointment_count, 0);
        },
        total_appointment_rate() {
            if (this.total_contact_count === 0) {
                return null;
            } else {
                return (this.total_appointment_count * 100 / this.total_contact_count).toFixed(2) + '%';
            }
        },
        total_direct_appointment_count() {
            return this.stats.reduce((prev, stat) => prev + stat.direct_appointment_count, 0);
        },
        total_direct_appointment_rate() {
            if (this.total_contact_count === 0) {
                return null;
            } else {
                return (this.total_direct_appointment_count * 100 / this.total_contact_count).toFixed(2) + '%';
            }
        },
    }
}
</script>
